import React from "react"
import loadable from "@loadable/component"
import { useTeamSectionItemConfiguration } from "../../hooks"
import { CAREERS_IMAGES_COLLAGE_IMAGE_CONTAINER } from "../../constants";
import { StyledImageContainer } from "./TeamSectionItem.style"

const Mobile = loadable(() => import("./components/Mobile"));

const TeamSectionItem = ({ images }) => {
  const { isDesktop, imageNumber, componentElements, changeImage } = useTeamSectionItemConfiguration(images);
  return (
    <>
      {isDesktop ? (
        <div
          className={CAREERS_IMAGES_COLLAGE_IMAGE_CONTAINER}
          onMouseEnter={changeImage}
        >
          <StyledImageContainer
            className={CAREERS_IMAGES_COLLAGE_IMAGE_CONTAINER}
            imageNumber={imageNumber}
          >
            {componentElements}
          </StyledImageContainer>
        </div>
      ) : (
        <Mobile images={images} />
      )}
    </>
  )
}

export default TeamSectionItem;
