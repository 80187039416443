import styled from "styled-components";

export const StyledImageContainer = styled.div`
    overflow: hidden;
    position: relative;

    & img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: 1s;
    }

    & img:nth-child(${({ imageNumber }) => imageNumber + 1}) {
        opacity: 1;
        position: static;
    }
`;
